export default {
  title: "Users",
  createNew: "Create new user",
  filterPlaceholder: "Filter by name or email",
  name: "Name",
  email: "Email",
  role: "Role",
  access: "Access",
  confirmDeleteMessage: "Are you sure you want to delete this user?",
  createUser: "Create User",
  editUser: "Edit User",
  generatePassword: "Generate Password",
  changePassword: "Change Password",
  updatePassword: "Update Password",
  forceChangePassword: "Force change password at next login",
  password: "Password",
  confirmPassword: "Confirm password",
  saveSuccessMessage: "User saved.",
  userPasswordResetAndLinkSent:
    "The user's password has been reset. A unique login link has been sent by email.",
  userIncompatibleSingleLoginLink:
    "A unique connection link cannot be created for this user.<br>The user must have access to Brickroad for the link to function.",
  roles: {
    approver: "Approver",
    siteadmin: "Site admin",
    viewer: "Viewer",
    manager: "Manager",
  },
  loginLinkCopied: "The link was successfully copied!",
  loginLinkCopiedError: "The link could not be copied, please try again.",
  welcomeEmailSent: "The message was successfully sent.",
  welcomeEmailSentError: "The message could not be sent, please try again.",
  exportingToCognito: "Cognito user's insertion is in progress.",
  exportCognitoSuccess: "User successfully copied to Cognito.",
  exportCognitoFailed: {
    user_not_eligible: "The user is not eligible to be added to Cognito.",
    user_already_exists: "The user already exists in Cognito.",
    insertion_failed: "Failed to copy the user to Cognito.",
  },
};
